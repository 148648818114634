import styled, { css } from 'styled-components';
import { Button as button } from 'antd';
import propTypes from 'prop-types';

const Button = styled(button)`

  box-shadow:none !important;
  height: 34px !important;
  line-height: 33px !important;
  ${(props) => (props.type === 'success' && props.ghost)
    && css`
      border: 1px solid #85AE00 !important;
      color:  #85AE00 !important;
      background-color: none !important;
    `}
  ${(props) => (props.type === 'success' && !props.ghost) && css`
    box-sizing: border-box !important;
    border: 1px solid rgba(0,0,0,0.1) !important;
    background-color: #85AE00 !important;
    color: #fff  !important;
  `}
  ${(props) => props.type === 'thin'
    && css`
      color: #4962F5 !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      line-height: 12px !important;
      height: 30px !important;
      font-weight: bold;
      background-color: #E8E9F1 !important;
    `}
  ${(props) => props.theme === 'tableEditIcon'
  && css`
    padding: 0 !important;
    height: 18px !important;
    min-width: 40px !important;
    width: 40px !important;
    opacity: 0.9 !important;
    font-size: 11px !important;
    font-weight: 500 !important;
    line-height: 12px !important;
    text-align: center !important;
  `}
  ${(props) => props.theme === 'statEditIcon'
  && css`
    padding: 5px !important;
    height: 26px !important;
    min-width: 40px !important;
    // width: 40px !important;
    opacity: 0.9 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    line-height: 12px !important;
    text-align: center !important;
  `}
  ${(props) => props.theme === 'tableRemoveIcon'
  && css`
    padding: 0 !important;
    height: 18px !important;
    min-width: 50px !important;
    width: 50px !important;
    opacity: 0.9 !important;
    font-size: 11px !important;
    font-weight: 500 !important;
    line-height: 12px !important;
    text-align: center !important;
  `}

  ${(props) => props.theme === 'previewIcon'
  && css`
    padding: 0 !important;
    height: 26px !important;
    min-width: 40px !important;
    opacity: 0.9 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 15px !important;
    text-align: center !important;
    margin-right: 5px;
  `}

    ${(props) => props.type === 'warning' && props.ghost
    && css`
      border: 1px solid #E97F00 !important;
      color:  #E97F00 !important;
    `}

    ${(props) => props.iconOnly
    && css`
      border: 0 none !important;
      background: transparent !important;
    `}

    ${(props) => props.theme === 'iconSmall'
    && css`
      width: 24px !important;
      height: 24px !important;
      min-width: auto !important;
      font-size: 12px !important;
      font-weight: 100 !important;
      line-height: 11px !important;
    `}
`;

Button.propTypes = {
  theme: propTypes.string,
};

Button.defaultProps = {
};

/** @component */
export default Button;
